/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import useStyles from '../../products.styles'

import AttributesVariant from './tabs/AttributesVariant'
import ImagesVariant from './tabs/ImagesVariant'
import InfoProduct from './tabs/InfoProduct'
import PriceVariant from './tabs/PriceVariant'

import DialogAlert from 'components/DialogAlert'
import Tabs from 'components/Tabs'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { DESCRIPTION_LENGHT } from 'constants/productsConstants'
import { PROMOTIONS } from 'constants/promotions'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import { checkSku, onlyDecimalsGreaterThanOne } from 'utils/utils'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
}

const VariationsForm = ({ variationSelected, listVariations, setIsLoadingBackDrop, onClose }) => {
  const { skuRepeated } = useProduct()
  const classes = useStyles()
  const { userLoggedin } = useContext(useAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [calculate, setCalculate] = useState(false)
  const { product, setProduct, isApprove } = useProduct()
  const [fechaError, setFechaError] = useState({
    startDate: false,
    endDate: false,
  })
  const [variation, setVariation] = useState({
    description: '',
    attributes: [],
    type: 'add',
    sku: null,
    images: [],
    rowIndex: -1,
    isActivePromotionPrice: false,
    assumedValue: 0,
    id: '',
    name: '',
    dateCreated: moment().format(),
    status: 'draft',
    featured: true,
    catalogVisibility: true,
    shortDescription: '',
    promotions: [],
    purchasable: true,
    totalSales: 0,
    externalUrl: '',
    taxStatus: '',
    taxClass: '',
    manageStock: true,
    quantityPerShoppingCart: null,
    stockStatus: '',
    backOrders: '',
    backOrdersAllowed: true,
    backOrdered: true,
    lowStockAmount: 0,
    soldIndividually: true,
    isUsed: false,
    checkTransport: false,
    packageType: '',
    applyWarranty: false,
    isPresale: true,
    shippingRequired: true,
    shippingTaxable: true,
    shippingClass: '',
    lastModifiedBy: '',
    shippingClassId: 0,
    reviewsAllowed: true,
    averageRating: '',
    ratingCount: 0,
    upsellIds: [],
    crossSellIds: [],
    purchaseNote: '',
    categoriesSearch: '',
    categories: [],
    tags: [],
    relatedProductsId: [],
    shipping: [],
    brands: [],
    // warranty: ''
  })
  const [fieldValidation, setFieldValidation] = useState({
    sku: variation.sku !== '',
    stockQuantity: variation.stockQuantity !== '',
    height: variation.dimensions?.height !== '',
    width: variation.dimensions?.width !== '',
    length: variation.dimensions?.length !== '',
    weight: variation.dimensions?.weight !== '',
    regularPrice: variation.regularPrice !== '',
    sale_price: variation.sale_price !== '',
    warehouse_id: variation.warehouse_id !== 0,
  })

  const [valueTab, setValueTab] = useState(0)
  const tabs = [
    { value: 0, name: 'Información básica' },
    { value: 1, name: 'Atributos' },
    { value: 2, name: 'Precio' },
    { value: 3, name: 'Imagen' },
  ]

  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: null,
    textButtonCancel: null,
  })

  const showDialogAlert = useCustomDialogAlert()

  const checkDimensions = (dimensions) => {
    if (!dimensions) {
      return false
    }
    const { height, length, width, weight } = dimensions

    if (!height || !length || !width || !weight) {
      return false
    }

    const expreg = /^[0-9]{1,}$|^[0-9]+([.][0-9]+)?$/
    if (expreg.test(height) && expreg.test(width) && expreg.test(length) && expreg.test(weight)) {
      return true
    }
    return false
  }
  const checkWarehouse = (warehouses) => {
    if (warehouses.length > 0) return true
    return false
  }
  const onSave = () => {
    const stockQuantity = variation?.stockQuantity.toString().replace(/,/g, '')
    const firstStep = variation?.sku && stockQuantity !== '' && parseInt(stockQuantity) >= 0
    const notHaveOption = variation.attributes.some((item) => item.value.length === 0)
    const secondStep = variation.attributes.length === product.attributes.length && !notHaveOption
    const { dimensions } = variation
    const dimensionProduct = checkDimensions(dimensions)
    const warehouseCheck = checkWarehouse(variation.warehouses)
    const date = new Date()
    const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const actualDayInitialDate = `${date.getFullYear()}-${month}-${day}T00:00:00`
    const actualDayFinalDate = `${date.getFullYear()}-${month}-${day}T23:59:59`
    const objectPromotion = variation.promotions.find((promo) => promo.name === PROMOTIONS)
    if (skuRepeated) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Valide que el sku no se encuentre registrado`,
        'Cerrar'
      )
      return
    }
    if (!firstStep || !dimensionProduct || !warehouseCheck) {
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos del paso INFORMACIÓN BÁSICA  no pueden estar vacíos`,
        'Cerrar'
      )
    }

    if (variation?.description.length > DESCRIPTION_LENGHT) {
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `La descripción no puede superar los ${DESCRIPTION_LENGHT} caracteres`,
        'Cerrar'
      )
    }
    if (!secondStep) {
      setValueTab(1)
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `Todos los ATRIBUTOS deben tener una opción`,
        'Cerrar'
      )
    }
    if (!variation?.regularPrice) {
      setValueTab(2)
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos del paso PRECIO no pueden estar vacíos`,
        'Cerrar'
      )
    }
    if (!objectPromotion?.value && variation?.isActivePromotionPrice) {
      setValueTab(2)
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos del paso PRECIO no pueden estar vacíos`,
        'Cerrar'
      )
    }
    if (variation.regularPrice === '' && !variation?.isActivePromotionPrice) {
      setValueTab(2)
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos del paso PRECIO no pueden estar vacíos`,
        'Cerrar'
      )
    }
    if (
      variation.regularPrice === '' &&
      objectPromotion?.value === '' &&
      variation?.isActivePromotionPrice
    ) {
      setValueTab(2)
      return showDialogAlert(
        true,
        '¡Advertencia!',
        `Los campos del paso PRECIO no pueden estar vacíos`,
        'Cerrar'
      )
    }
    if (parseInt(variation?.regularPrice, 10) <= parseInt(objectPromotion?.value, 10)) {
      setValueTab(2)

      return showDialogAlert(
        true,
        '¡Advertencia!',
        `El precio de la promoción deber de ser menor al de precio de producto`,
        'Cerrar'
      )
    }
    if (
      variation?.isActivePromotionPrice &&
      (!objectPromotion?.dateOnSaleTo || !objectPromotion?.dateOnSaleFrom)
    ) {
      setFechaError({
        startDate: !objectPromotion?.dateOnSaleFrom,
        endDate: !objectPromotion?.dateOnSaleTo,
      })
      setValueTab(2)
      showDialogAlert(true, '¡Advertencia!', `Las fechas de promoción son obligatorias`, 'Cerrar')
      return false
    }
    if (
      objectPromotion?.dateOnSaleTo !== undefined &&
      objectPromotion?.dateOnSaleFrom !== undefined &&
      moment(objectPromotion?.dateOnSaleTo) < moment(objectPromotion?.dateOnSaleFrom)
    ) {
      setFechaError({ endDate: true })
      setValueTab(2)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha final de promoción debe ser mayor a la fecha inicial`,
        'Cerrar'
      )
      return false
    }
    if (
      objectPromotion?.dateOnSaleFrom !== undefined &&
      moment(objectPromotion?.dateOnSaleFrom) < moment(actualDayInitialDate) &&
      variation.type === 'add' &&
      variation?.isActivePromotionPrice
    ) {
      setFechaError({ startDate: true })
      setValueTab(2)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha inicial de promoción debe ser mayor o igual al día de hoy`,
        'Cerrar'
      )
      return false
    }
    if (
      objectPromotion?.dateOnSaleTo !== undefined &&
      moment(objectPromotion?.dateOnSaleTo) < moment(actualDayFinalDate) &&
      variation.type === 'add' &&
      variation?.isActivePromotionPrice
    ) {
      setFechaError({ endDate: true })
      setValueTab(2)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `La fecha final de promoción debe ser mayor o igual al día de hoy`,
        'Cerrar'
      )
      return false
    }
    if (variation.images === null) {
      setValueTab(3)
      showDialogAlert(true, '¡Advertencia!', `Debe ingresar una imagen para la variación`, 'Cerrar')
      return false
    }

    const temp = listVariations ? [...listVariations] : []
    const index = variation.rowIndex
    variation.sku = variation.sku.replace(`${userLoggedin.name.toLowerCase()}-`, '')
    variation.isMemory = true
    setIsLoadingBackDrop(true)
    const skuMemory = product.variations.some(
      (element) => element.sku.trim().toLowerCase() === variation.sku.trim().toLowerCase()
    )
    if (variation?.type === 'add' && skuMemory) {
      setIsLoadingBackDrop(false)
      return showDialogAlert(true, '¡Advertencia!', `Ya existe un producto con este SKU.`, 'Cerrar')
    }
    const prueba = 0
    if (prueba === 0 || (variation.type === 'edit' && true)) {
      setIsLoadingBackDrop(false)
      variation.stockQuantity = stockQuantity
      variation.stock_status = Number(stockQuantity) > 0 ? 'instock' : 'outofstock'
      variation.assumedValue = variation.assumedValue ? Number(variation.assumedValue) : 0
      if (variation.sale_price) {
        variation.sale_price = String(variation.sale_price)
      }

      const image = variation.images ? variation.images : null
      if (index === -1 && variation.type === 'add') {
        variation.images = image
        temp.push(variation)
      } else {
        variation.images = image
        temp.splice(index, 1, variation)
      }
      setProduct({ ...product, variations: temp })
      onClose()
    } else {
      showDialogAlert(true, '¡Advertencia!', `Ya existe un producto con este SKU.`, 'Cerrar')
      setFieldValidation({
        ...fieldValidation,
        skuDuplicated: true,
      })
      setValueTab(0)
    }
  }

  const handleCloseDialog = () => {
    onClose()
    setDialogAlert({ ...dialogAlert, open: false })
    setIsLoading(false)
  }

  const changeTabs = (newValue) => {
    const date = new Date()
    const stockQuantity = variation?.stockQuantity.toString().replace(/,/g, '')
    const firstStep = variation?.sku && stockQuantity !== '' && parseInt(stockQuantity) >= 0
    const notHaveOption = variation.attributes.some((item) => item.option?.length === 0)
    const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const actualDayInitialDate = `${date.getFullYear()}-${month}-${day} 00:00:00`
    const secondStep = variation.attributes?.length === product.attributes?.length && !notHaveOption
    const { dimensions } = variation
    const dimensionProduct = checkDimensions(dimensions)
    const warehouseCheck = checkWarehouse(variation.warehouses)
    const descriptionLength = variation?.description?.length > DESCRIPTION_LENGHT
    const variationPromotion = variation.promotions.find(
      (promotion) => promotion.name === PROMOTIONS
    )
    switch (valueTab) {
      case 0:
        if (skuRepeated) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Valide que el SKU no se encuentre registrado o no correspona al SKU del padre.`,
            'Cerrar'
          )
          return
        }
        if (!onlyDecimalsGreaterThanOne(dimensions.weight)) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `El campo peso debe ser mayor o igual que uno`,
            'Cerrar'
          )
          return
        }
        if (!firstStep || !dimensionProduct || !warehouseCheck) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Los campos del paso INFORMACIÓN BÁSICA  no pueden estar vacíos`,
            'Cerrar'
          )
          return
        }
        if (descriptionLength) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `La descripción no puede superar los ${DESCRIPTION_LENGHT} caracteres`,
            'Cerrar'
          )
          return
        }

        if (variation.type !== 'edit' && !checkSku(variation?.sku || '')) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Verifique que el sku no tenga caracteres especiales, no comience con cero (0) y sea de máximo 15 caracteres.`,
            'Cerrar'
          )
          return
        }
        setValueTab(newValue)
        break
      case 1:
        if (secondStep) {
          setValueTab(newValue)
        } else {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Todos los ATRIBUTOS deben tener una opción`,
            'Cerrar'
          )
        }
        break
      case 2:
        if (!variation?.regularPrice) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Los campos del paso PRECIO no pueden estar vacíos`,
            'Cerrar'
          )
          return
        }
        if (
          !variation.promotions.find((promotion) => promotion.name === PROMOTIONS)?.value &&
          variation?.isActivePromotionPrice
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Los campos del paso PRECIO no pueden estar vacíos`,
            'Cerrar'
          )
          return
        }
        if (variation.regularPrice === '' && !variation?.isActivePromotionPrice) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Los campos del paso PRECIO no pueden estar vacíos`,
            'Cerrar'
          )
          return
        }
        if (
          variation.regularPrice === '' &&
          variationPromotion?.value === '' &&
          variation?.isActivePromotionPrice
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Los campos del paso PRECIO no pueden estar vacíos`,
            'Cerrar'
          )
          return
        }
        if (
          (!variationPromotion?.dateOnSaleFrom || !variationPromotion?.dateOnSaleTo) &&
          variationPromotion?.value
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Las fechas de promoción son obligatorias`,
            'Cerrar'
          )
          return
        }
        if (
          variationPromotion?.dateOnSaleTo !== undefined &&
          variationPromotion?.dateOnSaleFrom !== undefined &&
          moment(variationPromotion?.dateOnSaleTo) < moment(variationPromotion?.dateOnSaleFrom)
        ) {
          setFechaError({ endDate: true })
          setValueTab(2)
          showDialogAlert(
            true,
            '¡Advertencia!',
            `La fecha final de promoción debe ser mayor a la fecha inicial`,
            'Cerrar'
          )
          return
        }
        if (
          variationPromotion?.dateOnSaleFrom !== undefined &&
          moment(variationPromotion?.dateOnSaleFrom) < moment(actualDayInitialDate) &&
          variationPromotion?.value &&
          variation.type === 'add'
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `La fecha inicial de promoción debe ser mayor o igual al día de hoy`,
            'Cerrar'
          )
          return
        }
        if (
          parseInt(variation?.regularPrice, 10) <= parseInt(variation?.promotions[0]?.value, 10)
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `El precio de la promoción deber de ser menor al de precio de producto`,
            'Cerrar'
          )
        } else {
          setValueTab(newValue)
        }
        break
      default:
        setValueTab(newValue)
    }
  }

  useEffect(() => {
    if (variationSelected) {
      fieldValidation.sku = variationSelected.sku === ''
      fieldValidation.stockQuantity = variationSelected.stockQuantity === ''
      fieldValidation.regularPrice = variationSelected.regularPrice === ''
      fieldValidation.height = variationSelected.dimensions?.height === ''
      fieldValidation.width = variationSelected.dimensions?.width === ''
      fieldValidation.length = variationSelected.dimensions?.length === ''
      fieldValidation.weight = variationSelected.weight === ''
      fieldValidation.warehouse_id = variationSelected.warehouse_id === 0
      if (variationSelected.id || variationSelected.sku !== '') variationSelected.type = 'edit'
      variationSelected.parent_id = product.id

      setVariation(variationSelected)
    }
  }, [variationSelected])

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        classes={{ paperWidthXl: classes.modalVariationForm }}
        onClose={onClose}
        open
      >
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6">
            {!isApprove
              ? `${variation.type === 'edit' ? 'Editar' : 'Agregar nueva'} variación de producto`
              : 'variación de producto'}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.variantContent}>
          <Grid container alignItems="center" style={{ marginTop: 10 }}>
            <Grid item sm={12} xs={12}>
              <Tabs value={valueTab} tabs={tabs} onChange={changeTabs} />
              <TabPanel value={valueTab} index={0}>
                <InfoProduct
                  setIsLoadingBackDrop={setIsLoadingBackDrop}
                  setVariation={setVariation}
                  variation={variation}
                  Controller={Controller}
                  fieldValidation={fieldValidation}
                  setFieldValidation={setFieldValidation}
                  setCalculate={setCalculate}
                  calculate={calculate}
                  changeTabs={changeTabs}
                  onClose={onClose}
                />
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <AttributesVariant
                  setVariation={setVariation}
                  variation={variation}
                  fieldValidation={fieldValidation}
                  setFieldValidation={setFieldValidation}
                  changeTabs={changeTabs}
                  onClose={onClose}
                />
              </TabPanel>
              <TabPanel value={valueTab} index={2}>
                <PriceVariant
                  setVariation={setVariation}
                  variation={variation}
                  fieldValidation={fieldValidation}
                  setFieldValidation={setFieldValidation}
                  fechaError={fechaError}
                  setFechaError={setFechaError}
                  changeTabs={changeTabs}
                  onClose={onClose}
                />
              </TabPanel>
              <TabPanel value={valueTab} index={3}>
                <ImagesVariant
                  setVariation={setVariation}
                  variation={variation}
                  onSave={onSave}
                  changeTabs={changeTabs}
                  onClose={onClose}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialog}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />
    </>
  )
}

export default VariationsForm
